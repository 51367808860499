import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify} // Replace with an image relevant to your current job if available
              isBlog={false}
              title="Orion Solution"
              description="Currently serving as a Senior Laravel Developer at Orion Solution. Leading a team responsible for developing and maintaining web applications using Laravel 9 and Vue 3. Expertise includes integrating payment gateways such as Afterpay, Braintree, and PayPal, as well as optimizing databases for enhanced performance. Focused on delivering high-quality, user-friendly interfaces and efficient backend operations."
              ghLink="https://github.com/your-repository" // Update with relevant GitHub link if available
              demoLink="https://www.brooksrunning.com.au/" // Update with your company's website or relevant link if available
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode} // Replace with an image relevant to the project if available
              isBlog={false}
              title="Shree Navgam Visanagar Vanik Samaj Website"
              description="Developed a comprehensive website for Shree Navgam Visanagar Vanik Samaj using Laravel 11 and Vue 3. The website highlights the samaj’s rich history, charitable activities, and community events. It features functionalities for medical relief, hospitalization aid, educational support, and sports events. Implemented payment gateway integration with CCAvenue for streamlined donation processes."
              ghLink="https://github.com/your-repository" // Update with relevant GitHub link if available
              demoLink="https://snvsamaj.in/" // Update with the live website URL if available
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf} // Replace with an image relevant to the project if available
              isBlog={false}
              title="Ultimate Blogs"
              description="Developed Ultimate Blogs, a platform for showcasing a variety of blogs, using Next.js. Deployed on Netlify for seamless hosting and performance. The website provides a user-friendly interface for browsing and reading blog content, leveraging Next.js for efficient server-side rendering and static site generation."
              ghLink="https://github.com/your-repository" // Update with relevant GitHub link if available
              demoLink="https://ultimateblogs.netlify.app"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide} // Replace with an image relevant to the project if available
              isBlog={false}
              title="React Learning Website"
              description="Created a website to learn and practice React. The project was developed as a hands-on approach to understanding React concepts and is deployed on Netlify. The website demonstrates basic to advanced React functionalities and serves as a practical resource for honing React skills."
              ghLink="https://github.com/your-repository" // Update with relevant GitHub link if available
              demoLink="https://phenomenal-parfait-e56ba0.netlify.app"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
            // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
